import { Fancybox } from '@fancyapps/ui';
import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

window.addEventListener('load', function () {
  const Swiper = window.Swiper;

  new Swiper('.photo-grid-block', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 40,
    paginationClickable: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    breakpoints: {
      452: {
        slidesPerView: 18,
        spaceBetween: 0,
      },
    },
  });

  Fancybox.bind('[data-fancybox]', {});
});

function loadMoreGallery() {
  let button = document.querySelector('.load-more-gallery-button');
  let photos = document.querySelectorAll('.photo-grid__single');

  if (window.innerWidth > 451 && window.innerWidth < 992) {
    if (button) {
      button.addEventListener('click', function () {
        let visible_photo = document.querySelectorAll(
          '.photo-grid__single.visible'
        );

        for (let i = 0; i < 4; i++) {
          let nextElement = photos[visible_photo.length + i];
          if (nextElement) {
            nextElement.classList.add('visible');
          } else {
            button.remove();
            break;
          }
        }
      });
    }
  } else {
    $('.photo-grid__single').css('display', 'block');
    if (button) {
      button.remove();
    }
  }
}

window.addEventListener('load', loadMoreGallery);
window.addEventListener('resize', loadMoreGallery);
